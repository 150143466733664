<template>
  <div class="login-container mycoupons">
   <!-- <h3 style="color: red">我的优惠券</h3>-->
    <el-tabs v-model="activeName" stretch  @tab-click="tabsHandleClick">
      <el-tab-pane label="代金券" name="gift">
        <div class="page">
          <div class="wrapper" v-for="item in gifts" :key="item.id" @click="toOneCouponsInfo(item.gi_card_num)">
            <!-- 左侧box -->
            <div class="content">
              <!--<div class="couponCount">{{item.size}}张</div>-->
              <div class="oilline linwidth160 lineBt"></div>
              <div>
                <span class="moyfh">¥</span>
                <span  class="moneyNum">{{ item.gi_cou_amount }}</span>
              </div>
              <div style="margin-bottom: 10px;font-size: 14px;color: #fff;">
                {{ item.cou_title }}
              </div>
              <div class="oilline  linwidth160 lineBt"></div>
            </div>

            <div class="split-line"></div>
            <!-- 右侧box -->
            <div class="tip">
              <!--<div class="oilline linwidth227 lineBt "></div>-->
              <div class="vnameBx">
                  <div class="couponmaes" style="color: #000000;font-size: 17px;font-weight: 600;">{{item.me_name}}</div>
                  <div class="couponmaes" style="color: #000000;font-size: 16px;">{{ item.gi_cou_amount }}元代金券</div>
                  <div class="couponmaes" style="color: #409EFF;font-size: 14px;">全场{{ item.gift_name }}</div>
                  <div class="couponmaes" style="color: #909399;font-size: 14px;">领取时间：{{ item.gi_rec_dm.replaceAll('T', ' ') }}</div>
              </div>
              <!--<div class="oilline linwidth227 lineBt"></div>-->
              <!--<div :class="'btnbx'+  item.isJoin==200?'':'moveCm'  ">
              &lt;!&ndash;  <div class="'btnw'+ item.isJoin==200?'btnBgGray':'btnBgActive'  ">
                  <div class="btnn" bindtap="submitCilck">{{btnTxt}}</div>
                </div>&ndash;&gt;
              </div>-->
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="计次卡" name="times">
        <div class="page">
          <div class="wrapper-time" v-for="(item, index) in timeCards" :key="index"  @click="userTimes(item.sri_card_num,item.tim_unit)">
            <!-- 左侧box -->
            <div class="content-time">
              <div class="couponCount">{{item.sri_surp_count}}&nbsp;{{item.tim_unit}}</div>
              <div style="width: 100%;">
                <span class="moyfh-time">{{item.card_name}}</span>
              </div>
              <div>
                <!--<div style="color: #fff;font-size: 15px;">
                  剩余<i style="font-size:20px">{{item.sri_surp_count}}</i>{{item.tim_unit}}
                </div>-->
                <div style="font-size: 14px;color: #fff;margin-top: 20px;">
                  领取时间:{{item.rec_dm.replaceAll('T', ' ') }}
                </div>
              </div>
            </div>

            <div class="split-line"></div>
            <!-- 右侧box -->
            <div class="tip-time">
              立&nbsp;即<br/>使&nbsp;用
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import coupons from "../../api/coupons";
export default {
  data() {
    return {
      codeShow: true,
      timeCodeShow: true,
      codeType: "",
      gift_color: "Color010",
      time_color: "Color010",
      barcode_url: require("@/assets/code_type2.jpg"),
      qrcode_url: require("@/assets/code_type1.jpg"),
      predefineColors: [
        "#63b359",
        "#2c9f67",
        "#509fc9",
        "#5885cf",
        "#9062c0",
        "#d09a45",
        "#e4b138",
        "#ee903c",
        "#a9d92d",
        "#dd6549",
        "#cc463d",
      ],
      bgColorGradient: "#a9d92d",
      gifts: [],
      timeCards: [],
      openid: "",
      meid: "",
      activeName: "",
    };
  },
  created() {
      document.title = '我的优惠券';
    this.openid = this.$route.query.openid;
    this.meid = this.$route.query.meid;
    if(this.$route.query.tabs) {
      this.activeName = this.$route.query.tabs;
    } else {
      this.activeName = 'gift';
    }
    this.getAllCoupons();
  },
  methods: {
    tabsHandleClick(tab, event) {
      this.$router.push({
        path: "/mycoupons",
        query: {
          openid: this.$route.query.openid,
          meid: this.$route.query.meid,
          tabs: this.activeName,
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    showCode() {
      this.codeShow = !this.codeShow;
    },
    showTimes() {
      this.timeCodeShow = !this.timeCodeShow;
    },
    getAllCoupons() {
      let params = {
        openid: this.openid,
        meid: this.meid,
      };
      coupons.list(params).then((res) => {
        console.log(res);
        this.gifts = res.data.data.result;
        this.timeCards=res.data.data.times
      });
    },
    toOneCouponsInfo(cardNum) {
      this.$router.push({
        path: "/onecoupons",
        query: {
          cardNum,
        },
      });
    },
    userTimes(cardNum,tim_unit){
      this.$router.push({
        path:"/onetimecard",
        query: {
          cardNum,
          tim_unit
        },
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100%;
  width: 100%;
  /*padding: 5px 8px;*/
  overflow: scroll;
  .coupons {
    margin-bottom: 10px;
    width: 100%;
    height: 100px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 1px rgb(108, 126, 112);
    display: flex;
    flex-direction: row;
    .left {
      width: 70%;
      height: 100%;
      border-right: 1px dashed gray;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      margin-left: 5px;
    }
    .right {
      height: 100%;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        to right,
        rgb(127, 250, 209),
        rgb(83, 199, 160)
      );
    }
  }
  .onetimes {
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .time-top {
      width: 100%;
      height: 200px;
      background: linear-gradient(
        to right,
        rgb(127, 250, 209),
        rgb(83, 199, 160)
      );
      border-radius: 10px;
      box-shadow: 1px 1px 3px 1px rgb(108, 126, 112);
      display: flex;
      flex-direction: column;
      justify-content: center;
      .infos{
        height: 60px;
        margin-top: 5px;
      }
      .top-infos{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .bottom-infos{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .time-center{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .el-btn {
      width: 60%;
      height: 40px;
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      .fg {
        height: 35px !important;
      }
    }
  }
}

.page {
  width: 100%;
  height: 100%;
  min-height: 30px;
  /*padding-top: 20px;*/
  padding-bottom: 13px;
  box-sizing: border-box;
  position: relative;
  /*background: #000;*/
}


.wrapper {
  width: 365px;
  height: 105px;
  margin: 0 auto;
  display: flex;
  /*background: linear-gradient(135deg, rgba(219, 93, 115, 1) 0%, rgba(137, 29, 34, 1) 100%);*/
  background: linear-gradient(135deg, rgb(121, 187, 255) 0%, #409EFF 100%);
  border-radius: 13px;
  margin-bottom: 15px;
}

.wrapper-time {
  width: 365px;
  height: 105px;
  margin: 0 auto;
  display: flex;
  background: linear-gradient(135deg, rgb(121, 187, 255) 0%, #409EFF 100%);
  border-radius: 13px;
  margin-bottom: 15px;
}

/*前半部分样式*/
.content {
  position: relative;
  height: 109px;
  flex: 0 0 95px;
  text-align: center;
  overflow: hidden;
  border-top-left-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content-time {
  position: relative;
  height: 109px;
  flex: 0 0 265px;
  text-align: center;
  overflow: hidden;
  /*border-top-left-radius: 18px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*后半部分样式*/
.tip {
  padding-top: 5px;
  margin-left: -10px;
  background: #fff;
 /* position: relative;
  flex: 1;*/
  height: 105px;
  width: 265px;
  box-sizing: border-box;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  line-height: 23px;
  /*justify-content: center;*/
}

.tip-time {
  padding-top: 27px;
  font-weight: 600;
  margin-left: -10px;
  background: #fff;
  /* position: relative;
   flex: 1;*/
  height: 105px;
  width: 95px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  line-height: 23px;
  /*justify-content: center;*/
}

/*中间竖直虚线样式*/
.split-line {
  position: relative;
  flex: 0 0 0;
  margin: 0 10px 0 6px;
  border-left: 2px/* dashed #fff*/;
}

/*给虚线加两个伪类，基本样式如下*/
.split-line:before,
.split-line:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 8px;
  background: #EAEBF0;
  left: -8px;
  z-index: 1;
}

/*几个伪类化成的圆弧的样式以及位置（置于顶部）我把它放在一起了*/
.content:before,
.tip:before,
.split-line:before {
  border-radius: 0 0 8px 8px;
  top: 0;
}

.content-time:before,
.tip-time:before,
.split-line:before {
  border-radius: 0 0 8px 8px;
  top: 0;
}

/*几个伪类化成的圆弧的样式以及位置（置于底部）我把它放在一起了*/
.content-time:after,
.tip:after,
.split-line:after {
  border-radius: 8px 8px 0 0;
  bottom: 0;
}

.content-time:after,
.tip-time:after,
.split-line:after {
  border-radius: 8px 8px 0 0;
  bottom: 0;
}


.couponCount {
  width: 110px;
  height: 110px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(140, 31, 37, 1);
  transform: rotate(-45deg);
  position: absolute;
  text-align: center;
  line-height: 183px;
  top: -56px;
  left: -56px;
  background: linear-gradient(319deg, rgba(244, 219, 160, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.oilline {
  background: linear-gradient(to right,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 10%,
          rgba(255, 255, 255, 0.3) 20%,
          rgba(255, 255, 255, 0.4) 30%,
          rgba(255, 255, 255, 0.5) 40%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(255, 255, 255, 0.5) 60%,
          rgba(255, 255, 255, 0.4) 70%,
          rgba(255, 255, 255, 0.3) 80%,
          rgba(0, 0, 0, 0) 90%,
          rgba(0, 0, 0, 0) 100%);
  height: 2px;
}

.linwidth227 {
  width: 227px;
}

.linwidth160 {
  width: 160px;
}

.moyfh {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-right: -2px;
}

.moyfh-time {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-right: -2px;
}

.moneyNum {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}

.couponmaes {
  text-align: left;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  /*width: 260px;*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.vnameBx {
  text-align: center;
  width: 227px;
  margin: 5px 0;
}

.btnbx {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnw {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 148px;
  border-radius: 50%;
  margin-right: 30px;
}
.btnBgActive {
  color: rgba(140, 31, 37, 1);
  box-shadow: 0px 2px 29px 0px rgba(77, 12, 15, 0.56);
  background: linear-gradient(319deg, rgba(244, 219, 160, 1) 0%, rgba(255, 255, 255, 1) 100%);
}
.btnBgGray {
  box-shadow: 0px 2px 29px 0px rgba(77, 12, 15, 0.56);
  background:#999;
  color: #fff;
}
.btnn {
  font-size: 30px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid rgba(142, 33, 39, 1);
  text-align: center;
  box-sizing: border-box;
  padding: 18px;
  line-height: 46px;
}
.moveCm {
  animation: scale 0.8s ease-in-out infinite alternate;
}

@keyframes scale {
  0% {
    transform: scale(0.92);
  }

  100% {
    transform: scale(1);
  }
}
.oilline {
  background: -webkit-linear-gradient(right, rgba(251, 251, 251, 0.09), rgba(251, 251, 251, 0.59), #FFF);
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(left, rgba(251, 251, 251, 0.09), rgba(251, 251, 251, 0.59), #FFF);
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(left, rgba(251, 251, 251, 0.09), rgba(251, 251, 251, 0.59), #FFF);
  /* Firefox 3.6 - 15 */
  /*background: linear-gradient(to left, rgba(251, 251, 251, 0.09), rgba(251, 251, 251, 0.59), #FFF);*/
  /* 标准的语法（必须放在最后） */
  height: 2px;
  width: 75px;
}
.lineBt {
  margin-bottom: 5px;
}
.mycoupons .el-tabs:not(.el-tabs__header) {
    border: 0;
    background: #EAEBF0;
    min-height: 100%;
    /*border: 1px solid #DCDFE6;
    border-left: 0;
    border-right: 0;*/
}
</style>
<style>
    .mycoupons .el-tabs__nav-scroll {
        background: #fff;
    }
    .mycoupons .el-tabs__item {
        height: 50px;
        line-height: 50px;
        font-size: 15px;
    }
</style>
